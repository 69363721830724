@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");

body {
  margin: 0;
  font-family: "Aldrich", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
