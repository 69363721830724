.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.title {
  font-size: 2.5rem;
  letter-spacing: 0.3rem;
  text-align: center;
  color: green;
}

.form {
  width: 80%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.formControl {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.label {
  font-size: 1.5rem;
  color: green;
}

.input {
  background: rgb(39, 36, 36);
  font-size: 1.5rem;
  font-weight: bold;
  outline: none;
  border: none;
  color: green;
  width: auto;
}

.btn {
  font: inherit;
  margin-top: 0.5rem;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 50px;
  width: 100%;
  color: green;
  background-color: rgb(39, 36, 36);
  cursor: pointer;
}

.result {
  font-size: 2rem;
  color: green;
  width: 80%;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.result span {
  width: 100%;
  max-height: 12rem;
  overflow-wrap: break-word;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

@media (min-width: 880px) {
  .title {
    font-size: 3.5rem;
  }

  .formControl {
    flex-direction: row;
    width: 70%;
  }

  .btn {
    width: 60%;
  }

  .result {
    font-size: 3.5rem;
    width: 60%;
  }
}
